// ** React Imports
import { useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { renderRoutes } from "react-router-config";

// ** Redux & Store & Actions
import { connect } from "react-redux";
import { logout } from "store/modules/auth";

// ** Third Party Components
import loadable from "@loadable/component";

// ** Contexts
import authContext from "contexts/authContext";

// ** Lazy Loading Imports
const Landing = loadable(() => import("../Landing/Landing"));
const RecoverRedirect = loadable(
	() => import("containers/Recover/RecoverRedirect"),
	{
		ssr: false,
	}
);
const MainWrapper = loadable(() => import("./components/MainWrapper"));

// #####################################################

const MainComponent = ({ route: { routes }, logout }) => {
	const location = useLocation();

	const isAuth = useContext(authContext);

	if (
		typeof window !== "undefined" &&
		window?.history?.scrollRestoration &&
		window.history.scrollRestoration !== "manual"
	) {
		window.history.scrollRestoration = "manual";
	}
	// #####################################################

	// ** Wyświetlanie Strony 'Landing Page' dla ścieżki '/'
	// ** i niezalogowanych użytkowników,
	if (
		matchPath(location.pathname, {
			path: "/",
			exact: true,
		}) &&
		!isAuth
	) {
		return renderRoutes([
			{
				key: "landing",
				path: "/",
				component: Landing,
			},
		]);
	}

	// ** Przekierowanie po przypomnieniu hasła, wyniesiesone poza 'routes.jsx',
	// ** aby nie ładowało domyślnego layout (header, sidebar itp.),
	else if (
		matchPath(location.pathname, {
			path: "/login/update_password",
			exact: true,
		})
	) {
		logout();
		return renderRoutes([
			{
				key: "recover",
				path: "/login/update_password",
				component: RecoverRedirect,
			},
		]);
	}
	// ** Wyświetlanie domyślnego layout'u Datezone
	// ** Header, Sidebar + Content
	else {
		return <MainWrapper routes={routes} />;
	}
};

// #####################################################

const Main = MainComponent;

const mapStateToProps = ({ global: { language } }) => ({
	language,
});

export default connect(mapStateToProps, {
	logout,
})(Main);
